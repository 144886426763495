const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {}

const getUserInfo = () => {
  return userInfoLocalStorage
}

const state = {
  options: {
    style: "mapbox://styles/mokhatat/ckl1ee4010e0r18mx2plk1mqz",
    center: [39.8579,21.3891],
    zoom: 11,
  },
  control: {
    show: true,
    position: 'bottom-right'
  },
  accessToken: "pk.eyJ1IjoibW9raGF0YXQiLCJhIjoiY2tmOXNqZDQ2MDlwajMybzh6cWJseGV4cyJ9.cV3erPCk1FYgGKt2jPN4ng",
  AppActiveUser: getUserInfo(),
}

export default state
