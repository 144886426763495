export default {
  SET_CITIES(state, cities) {
    state.cities = cities
  },
  SET_CITY(state, city) {
    state.city = city
  },
  SET_ACTIVE_CITY(state, cityId) {
    const index = state.cities.findIndex((city => city.id == cityId));
    state.city = state.cities[index];
  },
  SET_META(state, meta) {
   state.meta.total = meta.total
   state.meta.to = meta.to
   state.meta.from = meta.from
   state.meta.last_page = meta.last_page
   state.meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.meta.next_page = meta.current_page + 1
   }
  },
}
