export default {
  plans: [],
  plans2: [],
  favourite_plans: [],
  favourite_plots: [],
  plot: {},
  favourite: {},
  favourite_plan: {},
  shared: {},
  shared_plan: {},
  feedback: {},
  plan: JSON.parse(localStorage.getItem('plan')) || {},
  plan_detail: {},
  yearly: {},
  quarterly: {},
  monthly: {},
  charts_categories: [],
  quarterly_categories: [],
  monthly_categories: [],
  yearlySeries: [],
  views: 0,
  meta: {
    current_page: 1,
    next_page: 1,
    per_page: 30,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
}
