import axios from 'axios'

const DOMAIN = ""
let baseURL = "https://api-dev.mokhatat.com/api/"

if (process.env.NODE_ENV === "production") {
  baseURL = "https://api-prod.mokhatat.com/api/"
}

export let url = baseURL;

export default axios.create({
    DOMAIN,
    baseURL: baseURL
})