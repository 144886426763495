import axios from "../../http/axios/index.js"

export default {
  SET_BEARER(state, accessToken) {
    localStorage.setItem('accessToken', accessToken)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  ADD_MESSAGE_COUNT(state, count) {
    state.message_count = state.message_count + count
  },
  GET_USER(state, payload) {
    state.profileUser = payload
  }
}
