import axios from "../../axios/index.js"

export default {
  register(payload) {
    return axios.post("customer/register", payload)
  },
  updateUser(payload) {
    return axios.post("customer/update", payload)
  },
  getUser() {
    return axios.get("customer/profile/get")
  },
  verifyPhone(payload) {
    return axios.post("customer/send-otp", payload)
  },
  confirmOtp(payload) {
    return axios.post("customer/confirm-otp", payload)
  },
  sendMessage(payload) {
    return axios.post("customer/contact-us", payload)
  },
  deleteAccount() {
    return axios.delete("api/account/delete")
  }
}
