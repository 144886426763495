import auth from "../../http/requests/auth/index.js"

import router from '@/router'

function getUrl() {
  let last_url = localStorage.getItem('last_url')
  if(last_url != undefined) {
    localStorage.removeItem('last_url')
    return last_url
  }
  return ""
}

export default {
  register ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.register(payload)
        .then(response => {
          if (response.data.token) {

            commit('SET_BEARER', response.data.token)

            commit('UPDATE_USER_INFO', response.data.user, {root: true})

            let last_url = getUrl()

            if (last_url != "") {
              router.push(last_url)
            }
            else {
              location.reload()
            }

            resolve(response)
          } else {
            reject({message: 'Wrong Email or Password'})
          }

        })
        .catch(error => {
           reject(error)
         })
    })
  },
  updateUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.updateUser(payload)
        .then(response => {
          if (response.data) {
            // console.log(3,response.data.data)
            // commit('SET_BEARER', response.data.token)

            commit('UPDATE_USER_INFO', response.data.data, {root: true})

            // let last_url = getUrl()

            // if (last_url != "") {
            //   router.push(last_url)
            // }
            // else {
            //   location.reload()
            // }

            resolve(response)
          } else {
            reject({message: 'Wrong Email or Password'})
          }

        })
        .catch(error => {
           reject(error)
         })
    })
  },
  getProfileUser ({ commit }) {
    return new Promise((resolve, reject) => {
      auth.getUser()
        .then(response => {
          if (response.data) {
            // console.log(6,response.data)
            // commit('SET_BEARER', response.data.token)

            commit('GET_USER', response.data.data)

            // let last_url = getUrl()

            // if (last_url != "") {
            //   router.push(last_url)
            // }
            // else {
            //   location.reload()
            // }

            resolve(response)
          } else {
            reject({message: 'Wrong Email or Password'})
          }

        })
        .catch(error => {
           reject(error)
         })
    })
  },
  verifyPhone ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.verifyPhone(payload)
        .then(response => {
          if (response.data.status === "success") {
            localStorage.setItem('phone', payload.phone)
            commit('SET_STATUS', "otp_sent")
            resolve(response)
          } else {
            reject({message: 'Invalid Phone'})
          }

        })
        .catch(error => {
           reject(error)
         })
    })
  },
  confirmOtp ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.confirmOtp(payload)
        .then(response => {
          if (response.data.status === "success") {
            let status = "unregistered"
            if(response.data.profile_completed === true) {
              commit('SET_BEARER', response.data.token)

              commit('UPDATE_USER_INFO', response.data.user, {root: true})

              status = "registered"

              let last_url = getUrl()

              if (last_url != "") {
                router.push(last_url)
              }
              else {
                location.reload()
              }
            }
            commit('SET_STATUS', status)

            resolve(response)
          } else {
            reject({message: 'Invalid OTP'})
          }

        })
        .catch(error => {
           reject(error)
         })
    })
  },
  sendMessage ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.sendMessage(payload)
        .then(response => {
          if (response.data.status === "success") {
            commit('ADD_MESSAGE_COUNT', 1)
            resolve(response)
          } else {
            reject({message: 'Invalid data provided'})
          }
        })
        .catch(error => {
           reject(error.response)
         })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve) => {
      auth.refreshToken().then(response => { resolve(response) })
    })
  },
  deleteAccount () {
    return new Promise((resolve, reject) => {
      auth.deleteAccount()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
           reject(error.response)
         })
    })
  },
}
