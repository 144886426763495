export default {
  subClassification(sub) {
    return sub.toLowerCase().split(" ").join("_")
  },
  numberFormat(val) {
    return new Intl.NumberFormat('en-US', {maximumSignificantDigits: 5}).format(parseFloat(val))
  },
  hijriYear(year) {
    if (isNaN(year) || year == "")
      return ""
    return Math.round((year - 622) * 1.03)
  }
}
