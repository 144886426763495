let quarter = {
  "1": "الربع الاول",
  "2": "الربع الثاني",
  "3": "الربع الثالث",
  "4": "الربع الرابع"
}

let month = {
  "Jan": "محرم",
  "Feb": "صفر",
  "Mar": "ربيع ١",
  "Apr": "ربيع ٢",
  "May": "جماد ١",
  "Jun": "جماد ٢",
  "Jul": "رجب",
  "Aug": "شعبان",
  "Sep": "رمضان",
  "Oct": "شوال",
  "Nov": "ذو القعدة",
  "Dec": "ذو الحجة"
}

let emptyQuarter = [
  {
    position: "1",
    value: "10",
    text: "الربع الاول"
  },
  {
    position: "2",
    value: "9",
    text: "الربع الثاني"
  },
  {
    position: "3",
    value: "8",
    text: "الربع الثالث"
  },
  {
    position: "4",
    value: "7",
    text: "الربع الرابع"
  }
]
export {quarter, emptyQuarter, month}
