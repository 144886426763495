import axios from "../../axios/index.js"

export default {
  fetchCities(meta) {
    return axios.get("customer/cities", {
      params: meta
      })
  },
  fetchAllCities(meta) {
    return axios.get("customer/all-cities", {
      params: meta
      })
  },
  fetchCity(cityId) {
    return axios.get(`customer/cities/${cityId}`)
  }
}
