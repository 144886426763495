export default {
    en: {
      residential: "Residential",
      commercial: "Commercial",
      price_per_sq_meter: "Price per square meter",
      number_of_transactions: "Number of transactions",
      district: "District",
      city: "City",
      meter_sq: "Meter Square",
      plot_no: "Plot No",
      en_date: "English Date",
      hij_date: "Hij Date",
      classification: "Classification",
      sub_classification: "Sub Classification",
      raw_land: "Raw Land",
      building: "Building",
      villa: "Villa",
      apartment: "Apartment",
      shop: "Shop",
      commercial_center: "Commercial Center",
      palace: "Palace",
      house: "House",
      appendix: "Appendix",
      chalet: "Chalet",
      agriculture: "Agriculture",
      amount: "Amount",
      send_feedback: "Send Feedback",
      attached_file: "Attached File",
      my_account: "My Account",
      favourite: "Favourite",
      plan_no: "Plan No",
      stat: "Statistics",
      contact_us: "Contact Us",
      mobile: "mobile",
      name: "Name",
      email: "Email",
      message: "Message",
      send: "Send",
      privacy: "Privacy & Terms",
      na: "N/A",
      min_amount: "Min Amount",
      max_amount: "Max Amount",
      from_meter_sq: "From Meter Square",
      to_meter_sq: "To Meter Square",
      hij_start_date: "Hij Start Date",
      hij_end_date: "Hij End Date",
      invoice_number: "Invoice Number",
      year_range: "Year Range",
      select_classification: "Select Classification",
      action: "Action",
      invoice_no: "Invoice No",
      view: "View",
      filter: "Filter",
      with_coordinates: "With Coordinates",
      class_by_year: 'Classification by ',
      avg_price_by_year: 'Average Price by ',
      volume_by_year: 'Volume of Transaction by ',
      avg_price_subclass: 'Average Price by Sub-classification',
      transact_subclass: 'Transaction by Sub-classification',
      sum_by_subclass: 'Sum of Transaction by Sub-classification',
      year: 'Year',
      month: 'Month',
      plans_list: 'Plans List',
      choose_plan: 'Choose Plan',
      search_plans: 'Search Plans',
      no_plans: 'There are no plans available to select',
      transaction_login: 'Login to view transaction chart',
      login: 'Login',
      test_style: 'Test Style'
    },
    ar: {
      residential: "سكني",
      commercial: "تجاري",
      price_per_sq_meter: "قيمة المتر المربع",
      number_of_transactions: "عدد الصفقات المسجلة",
      district: "الحي.",
      city: "المدينة.",
      meter_sq: "المساحة.",
      plot_no: "رقم القطعة",
      plan_no: "رقم المخطط",
      en_date: "تاريخ الصفقة بالميلادي",
      hij_date: "تاريخ الصفقة بالهجري",
      classification: "نوع العقار",
      sub_classification: "التصنيف",
      raw_land: "أرض",
      building: "عمارة",
      villa: "فيلا",
      apartment: "شقة",
      shop: "معرض",
      commercial_center: "مركز تجاري",
      palace: "قصر",
      house: "بيت",
      appendix: "ملحق",
      chalet: "استراحة",
      agriculture: "ارض زراعية",
      amount: "القيمة",
      send_feedback: "طلب تعديل معلومة",
      attached_file: "المرفقات",
      my_account: "حسابي",
      favourite: "المفضلة",
      stat: "الاحصائيات",
      contact_us: "اتصل بنا",
      mobile: "الجوال",
      name: "الاسم",
      email: "الايميل",
      message: "الرسالة",
      send: "ارسل",
      privacy: "الشروط والاحكام",
      na: "لايوجد",
      min_amount: "الحد الادنى",
      max_amount: "الحد الاعلى",
      from_meter_sq: "اقل مساحة",
      to_meter_sq: "اعلى مساحة",
      hij_start_date: "بداية",
      hij_end_date: "نهاية",
      invoice_number: "رقم الصفقة",
      year_range: "السنوات",
      select_classification: "تصنيف العقار",
      action: "الخريطة",
      invoice_no: "رقم الصفقة",
      view: "استكشف",
      filter: "البحث",
      with_coordinates: "الاحداثيات",
      class_by_year: 'التصنيف',
      avg_price_by_year: 'متوسط السعر',
      volume_by_year: 'اجمالي الصفقات',
      avg_price_subclass: 'متوسط سعر العقار حسب التصنيف سنوي',
      transact_subclass: 'متوسط الصفقة حسب التصنيف',
      sum_by_subclass: 'مجموع الصفقات حسب التصنيف',
      year: 'سنة',
      month: 'شهر',
      plans_list: 'قائمة المخططات',
      choose_plan: 'اختر المخطط',
      search_plans: 'بحث المخططات',
      no_plans: 'لايوجد مخطط للاختيار',
      transaction_login: 'الرجاء تسجيل الدخول لعرض المعلومات',
      login: 'تسجيل الدخول',
      test_style: 'تجريبي'
    }
}
