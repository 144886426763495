import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
      {
          path: '',
          component: () => import('@/layouts/full-page/FullPage.vue'),
          children: [
            {
                path: '/',
                name: 'landing',
                component: () => import('./views/Landing'),
                meta: {
                    rule: 'public',
                    title: 'الشروط والاحكام'
                 }
              }
          ]
      },
      {
          path: '',
          component: () => import('@/layouts/full-page/StaticFullPage.vue'),
          children: [
              {
                  path: '/contact',
                  name: 'contact',
                  component: () => import('./views/Contact'),
                  meta: {
                      rule: 'public',
                      title: 'Contact Us'
                   }
              },
              {
                  path: '/privacy',
                  name: 'privacy',
                  component: () => import('./views/Privacy'),
                  meta: {
                      rule: 'public',
                      title: 'Privacy & Terms'
                   }
              },
              {
                path: '/transactions',
                name: 'transactions',
                component: () => import('./views/Transactions'),
                meta: {
                    rule: 'public',
                    title: 'Transactions'
                 }
              },
              {
                path: '/real-estate-statistics',
                name: 'statistics',
                component: () => import('./views/Statistics'),
                meta: {
                    rule: 'public',
                    title: 'Statistics'
                 }
              },
              {
                path: '/monthly-transaction',
                name: 'monthly-transaction',
                component: () => import('./views/MonthlyTransaction'),
                meta: {
                    rule: 'public',
                    title: 'Monthly Transaction'
                 }
              },
              {
                path: '/monthly-comparison',
                name: 'monthly-comparison',
                component: () => import('./views/MonthlyComparison'),
                meta: {
                    rule: 'public',
                    title: 'Monthly Comparison'
                 }
            },
            {
                path: '/monthly-comparison-boxes',
                name: 'monthly-comparison-boxes',
                component: () => import('./views/MonthlyComparisonBoxes'),
                meta: {
                    rule: 'public',
                    title: 'Monthly Comparison Boxes'
                 }
            },
            {
                path: '/top-transactions',
                name: 'top-transactions',
                component: () => import('./views/TopTransactions'),
                meta: {
                    rule: 'public',
                    title: 'Top Transactions'
                 }
            }
          ]
      },
      {
          path: '',
          component: () => import('@/layouts/full-page/Map.vue'),
          children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('./views/Home'),
                meta: {
                    rule: 'public',
                    title: 'Mokhatat',
                    sticky: false
                 }
              },
              {
                  path: '/city/:cityId',
                  name: 'city',
                  component: () => import('./views/CitySearch'),
                  meta: {
                      rule: 'private',
                      title: 'Mokhatat',
                      sticky: true
                   }
              },
              {
                  path: '/plot/:plotId',
                  name: 'plot',
                  component: () => import('./views/PlotDetails'),
                  meta: {
                      rule: 'public',
                      title: 'Mokhatat',
                      sticky: true
                   }
              },
              {
                  path: '/plot/:plotId/favourite',
                  name: 'favourite',
                  component: () => import('./views/Note'),
                  meta: {
                      rule: 'private',
                      title: 'Mokhatat',
                      sticky: true
                   }
              },
              {
                path: '/plan/:planId/favourite',
                name: 'plan-favourite',
                component: () => import('./views/PlanFavourite'),
                meta: {
                    rule: 'private',
                    title: 'Mokhatat',
                    sticky: true
                 }
              },
              {
                  path: '/plot/:plotId/feedback',
                  name: 'feedback',
                  component: () => import('./views/Feedback'),
                  meta: {
                      rule: 'private',
                      title: 'Mokhatat',
                      sticky: true
                   }
              },
          ]
      },
      {
          path: '',
          component: () => import('@/layouts/full-page/User.vue'),
          children: [
            {
                path: '/saved',
                name: 'saved',
                component: () => import('./views/Saved'),
                meta: {
                    rule: 'private',
                    title: 'Mokhatat',
                    sticky: true
                 }
              },
              {
                  path: '/profile',
                  name: 'profile',
                  component: () => import('./views/Profile'),
                  meta: {
                      rule: 'private',
                      title: 'Mokhatat - Profile',
                      sticky: true
                   }
              },
              {
                  path: '/stats',
                  name: 'stats',
                  component: () => import('./views/Stats'),
                  meta: {
                      rule: 'private',
                      title: 'Mokhatat - Stats',
                      sticky: true
                   }
              },
          ]
      },
      {
        path: '*',
        redirect: '/home'
      }
    ],
})

router.beforeEach((to, from, next) => {
  if(to.meta.rule != 'public') {
    if (!store.state.auth.isUserLoggedIn()) {
      localStorage.setItem('last_url', to.fullPath)
      next('/home?login=true')
    }
  }
   next()
})
/*
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
}) */

export default router
