import state from './moduleCityState.js'
import mutations from './moduleCityMutations.js'
import actions from './moduleCityActions.js'
import getters from './moduleCityGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
