//import utils from '@/helper/utils.js'

export default {
   monthSeries(state) {
     let total_transactions = [],
         volume_of_transactions = [],
         size_of_transaction = [],
         avg_trans_per_day = [],
         avg_trans_volume_per_day = [],
         avg_trans_volume = []
     for (const [key] of Object.entries(state.monthly)) {
         total_transactions.push(state.monthly[key]["total_transactions"])
         volume_of_transactions.push(state.monthly[key]["volume_of_transactions"])
         size_of_transaction.push(state.monthly[key]["size_of_transaction"])
         avg_trans_per_day.push(state.monthly[key]["avg_trans_per_day"])
         avg_trans_volume_per_day.push(state.monthly[key]["avg_trans_volume_per_day"])
         avg_trans_volume.push(state.monthly[key]["avg_trans_volume"])
     }
     return [{
       name: "Total Transactions",
       data: total_transactions
      },
      {
        name: "Volume of Transactions",
        data: volume_of_transactions
      },
      {
        name: "Size of Transactions",
        data: size_of_transaction
      },
      {
        name: "Average Trans Per Day",
        data: avg_trans_per_day
      },
      {
        name: "Average Trans Volume Per Day",
        data: avg_trans_volume_per_day
      },
      {
        name: "Average Trans Volume",
        data: avg_trans_volume
      },
     ]
   },
   monthComparisonSeries(state) {
    let total_transactions = [],
        sum_of_transactions = []

    for (const [key] of Object.entries(state.monthly_comparison)) {
        total_transactions.push(state.monthly_comparison[key]["total_transactions"])
        sum_of_transactions.push(state.monthly_comparison[key]["sum_of_transactions"])
    }
    return [{
      name: "Total Transactions",
      data: total_transactions
     },
     {
       name: "Sum of Transactions",
       data: sum_of_transactions
     },
    ]
  },
  listSubClassifications(state) {
    return state.classifications.map((classification) => {
      return {
         text: classification.name,
         value: classification.id
      }
    })
  }
}
