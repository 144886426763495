import plan from "../../http/requests/plan/index.js"

export default {
  fetchPlans({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchPlans(payload.meta)
        .then((response) => {
          if (response.status === 401) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userInfo')
            location.replace('/home')
            location.reload()
          }
           if(response.data.data) {
             commit('SET_PLANS', response.data.data)
             commit('SET_YEARLY_STATS', [])
             commit('SET_QUARTERLY_STATS', [])
             commit('SET_MONTHLY_STATS', [])
             commit('SET_META', response.data.meta)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userInfo')
            location.replace('/home')
            location.reload()
          }
          reject(error)
        })
    })
  },
  fetchPlans2({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchPlans2(payload.meta)
        .then((response) => {
          if(response.data.data) {
            commit('SET_PLANS2', response.data.data)
            resolve(response)
          } else reject({message: response.data.message})
        })
        .catch((error) => {reject(error)})
    })
  },
  clearPlans({commit}) {
    return new Promise((resolve) => {
      commit('SET_PLANS2', [])
      resolve()
    })
  },
  fetchYearlyStatistics({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchYearlyStatistics(payload.meta)
        .then((response) => {
           if(response.data) {
             commit('SET_YEARLY_STATS', response.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchQuarterlyStatistics({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchQuarterlyStatistics(payload.meta)
        .then((response) => {
           if(response.data) {
             commit('SET_QUARTERLY_STATS', response.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchMonthlyStatistics({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchMonthlyStatistics(payload.meta)
        .then((response) => {
           if(response.data) {
             commit('SET_MONTHLY_STATS', response.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  favouritePlans({ commit }) {
    return new Promise((resolve, reject) => {
      plan.favouritePlans()
        .then((response) => {
           if(response.data.data[0]) {
             commit('SET_FAVOURITE_PLANS', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  favouritePlots({ commit }) {
    return new Promise((resolve, reject) => {
      plan.favouritePlots()
        .then((response) => {
           if(response.data.data[0]) {
             commit('SET_FAVOURITE_PLOTS', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  loadMorePlans({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchPlans(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             commit('UPDATE_PLANS', response.data.data)
             commit('SET_META', response.data.meta)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPlot({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchPlot(payload.meta)
        .then((response) => {
           if(response.data.data) {
             let data = {}
             if(response.data.data[0])
               data = response.data.data[0]
             commit('SET_PLOT', data)
             resolve(response)
         } else {
             if (response.status === 401) {
               localStorage.removeItem('accessToken')
               localStorage.removeItem('userInfo')
               location.replace('/home')
             }
            reject({message: response.data.message})
         }
        }).catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userInfo')
            location.replace('/home')
          }
          commit('SET_PLOT', {})
          reject(error)
        })
    })
  },
  fetchPlanDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchPlanDetail(payload.meta)
        .then((response) => {
           if(response.data.data) {
             let data = {}
             if(response.data.data[0])
               data = response.data.data[0]
             commit('SET_PLAN_DETAIL', data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          commit('SET_PLAN_DETAIL', {})
          reject(error)
        })
    })
  },
  fetchPlan({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchPlan(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             commit('ADD_TO_PLANS', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addFeedback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.addFeedback(payload)
        .then((response) => {
           if(response.data.status === 'success') {
             commit('SET_FEEDBACK', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addFavourite({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.addFavourite(payload.meta)
        .then((response) => {
           if(response.data.status === 'success') {
             commit('SET_FAVOURITE', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addFavouritePlan({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.addFavouritePlan(payload.meta)
        .then((response) => {
           if(response.data.status === "success") {
             commit('SET_FAVOURITE_PLAN', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createNewFavourite({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plan.createNewFavourite(payload.meta)
        .then((response) => {
           if(response.data.success === true) {
             commit('SET_FAVOURITE', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  recordShareView({ commit }, shareId) {
    return new Promise((resolve, reject) => {
      plan.recordShareView(shareId)
        .then((response) => {
           if(response.data.status === 'success') {
             commit('SET_SHARED', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  recordPlanView({ commit }, shareId) {
    return new Promise((resolve, reject) => {
      plan.recordPlanView(shareId)
        .then((response) => {
           if(response.data.status === 'success') {
             commit('SET_PLAN_SHARED', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeFavouritePlot({ commit }, plotId) {
    return new Promise((resolve, reject) => {
      plan.removeFavouritePlot(plotId)
        .then((response) => {
           if(response.status === 200) {
             commit('REMOVE_FAVOURITE_PLOT', plotId)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeFavouritePlan({ commit }, planId) {
    return new Promise((resolve, reject) => {
      plan.removeFavouritePlan(planId)
        .then((response) => {
           if(response.status === 200) {
             commit('REMOVE_FAVOURITE_PLAN', planId)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addPlotDetails({ commit }, payload) {
    commit('SET_PLAN_COORDS', payload)
  },
  updateViews({commit}) {
    commit('UPDATE_VIEWS')
  },

}
