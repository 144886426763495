export default {
   listCities(state) {
     return state.cities.map(city => {
       return {
         value: city.id,
         text: city.name
       }
     })
   }
}
