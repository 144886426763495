import transaction from "../../http/requests/transaction/index.js"
import i18n from '../../i18n/i18nData'

export default {
  fetchMonthlyTransactionReport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      transaction.fetchMonthlyTransactionReport(payload.meta)
        .then((response) => {
           if(response.data) {
             commit('SET_MONTHLY', response.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchMonthlyComparison({ commit }, payload) {
    return new Promise((resolve, reject) => {
      transaction.fetchMonthlyComparison(payload.meta)
        .then((response) => {
           if(response.data) {
             commit('SET_MONTHLY_COMPARISON', response.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  topTransactions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      transaction.topTransactions(payload.meta)
        .then((response) => {
           if(response.data.data) {
             commit('SET_TOP_TRANSACTIONS', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTransactions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      transaction.fetchTransactions(payload.meta)
        .then((response) => {
           if(response.data.data) {
             commit('SET_TRANSACTIONS', response.data.data)
             commit('SET_META', response.data.meta)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          commit('SET_TRANSACTIONS', [])
          reject(error)
        })
    })
  },
  fetchClassifications({ commit }, payload) {
    return new Promise((resolve, reject) => {
      transaction.fetchClassifications(payload.meta)
        .then((response) => {
           if(response.data.data) {
             commit('SET_CLASSIFICATIONS', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchStatOne({ commit }, payload) {
    return new Promise((resolve, reject) => {
      transaction.fetchStatOne(payload.meta)
        .then((response) => {
          if(response.data) {
            let categories = [], residential = [], commercial = [], average_price = [], volume_of_transaction = []
            for (let key in response.data) {
              categories = [...categories, key]
              residential = [...residential, response.data[key].residential_count]
              commercial = [...commercial, response.data[key].commercial_count]
              average_price = [...average_price, response.data[key].average_price]
              volume_of_transaction = [...volume_of_transaction, response.data[key].volume_of_transaction]
            }
            residential.length && residential.sort(function(a, b) {return a - b});
            commercial.length && commercial.sort(function(a, b) {return a - b});
            average_price.length && average_price.sort(function(a, b) {return a - b});
            volume_of_transaction.length && volume_of_transaction.sort(function(a, b) {return a - b});
            let series = [
              { name: i18n.ar["residential"], data: residential },
              { name: i18n.ar["commercial"], data: commercial }
            ];
            let series2 = [{ name: i18n.ar["avg_price_by_year"], data: average_price }]
            let series3 = [{ name: i18n.ar["volume_by_year"], data: volume_of_transaction }]
            if (Array.isArray(response.data) && !response.data.length) {
              reject({message: 'No transaction for the selected plan'})
            } else {
              commit('SET_STAT_ONE', [
                {series, categories},
                {series: series2, categories},
                {series: series3, categories},
              ]); resolve(response)
            }
          } else reject({message: response.data.message})
        }).catch(error => {
          if (error.response.status === 401) reject({message: i18n.ar["transaction_login"], status: 401})
          else reject({message: 'Encountered an error, Please try again'})
      })
    })
  },
  fetchStatTwo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      transaction.fetchStatTwo(payload.meta)
        .then((response) => {
          if(response.data) {
            let categories = [], average_price = [], total_transactions = [], volume_of_transaction = []
            for (let key in response.data) {
              categories = [...categories, formatKey(key)]
              average_price = [...average_price, response.data[key].average_price]
              total_transactions = [...total_transactions, response.data[key].total_transactions]
              volume_of_transaction = [...volume_of_transaction, response.data[key].volume_of_transaction]
            }
            average_price.length && average_price.sort(function(a, b) {return a - b});
            total_transactions.length && total_transactions.sort(function(a, b) {return a - b});
            volume_of_transaction.length && volume_of_transaction.sort(function(a, b) {return a - b});
            let series = [
              { name: i18n.ar["avg_price_by_year"], data: average_price },
              { name: 'Total Transaction', data: total_transactions },
              { name: i18n.ar["volume_by_year"], data: volume_of_transaction }
            ]
            let data = [
              {series: [series[0]], categories},
              {series: [series[1]], categories},
              {series: [series[2]], categories}
            ]
            commit('SET_STAT_TWO', data); resolve(response)
          } else reject({message: response.data.message})
        }).catch(error => reject(error))
    })
  },
}

const formatKey = (key) => {
  switch (key) {
    case 'Building':
      return i18n.ar["building"]
    case 'Raw Land':
      return i18n.ar["raw_land"]
    case 'Agriculture':
      return i18n.ar["agriculture"]
    case 'Villa':
      return i18n.ar["villa"]
    case 'Apartment':
      return i18n.ar["apartment"]
    case 'Shop':
      return i18n.ar["shop"]
    case 'House':
      return i18n.ar["house"]
    case 'Palace':
      return i18n.ar["palace"]
    case 'Appendix':
      return i18n.ar["appendix"]
    case 'Chalet':
      return i18n.ar["chalet"]
    default:
      return key
  }
}


export let formatLabel = (num, digits) => {
  let si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "Bn" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}


export let formatMoney = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign
      + (j ? i.substr(0, j)
        + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
      + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    return amount.toFixed(2)
  }
};