export default {
  isUserLoggedIn: () => {
    let user = JSON.parse(localStorage.getItem('userInfo'))
    if(user) {
        return true
    }
    else {
       return false
    }
  },
  status: "",
  message_count: 0,
  profileUser: null
}
