import city from "../../http/requests/city/index.js"

export default {
  fetchCities({ commit }, payload) {
    return new Promise((resolve, reject) => {
      city.fetchCities(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             commit('SET_CITIES', response.data.data)
             commit('SET_META', response.data.meta)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAllCities({ commit }, payload) {
    return new Promise((resolve, reject) => {
      city.fetchAllCities(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             let data = [...response.data.data]
             data.sort(function (a, b) {
               return a.name.localeCompare(b.name);
             });
             commit('SET_CITIES', data)
             commit('SET_META', response.data.meta)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCity({ commit }, cityId) {
    return new Promise((resolve, reject) => {
      city.fetchCity(cityId)
        .then((response) => {
           if(response.data.data) {
             commit('SET_CITY', response.data.data)
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setCity({ commit }, cityId) {
    commit('SET_ACTIVE_CITY', cityId)
  },
}
