import utils from '@/helper/utils.js'

import {emptyQuarter, month as mon} from '@/data/chartsData.js'

export default {
   listPlans(state) {
     return state.plans.map(plan => {
       return {
         value: plan.planNo,
         text: plan.planNo
       }
     })
   },
   listPlansWithDistrict(state) {
    return state.plans.map(plan => {
      return {
        value: plan.planNo,
        text: `${plan.planNo} - ${plan.district}`,
      }
    })
  },
   listYears(state) {
     let years = Object.keys(state.yearly)
     return years.map(year => {
       return {
         value: year,
         text: utils.hijriYear(year)
       }
     })
   },
   listQuarters(state) {
     let quarters = Object.keys(state.quarterly)
     let empty_quarters = emptyQuarter

     quarters.forEach((data) => {

       let qIndex = empty_quarters.findIndex(q => q.position == data)
       
       if (qIndex != -1) {
         empty_quarters[qIndex]["value"] = data
       }
     })

     return empty_quarters.map(quarter => {
       return {
         value: quarter.value,
         text: quarter.text
       }
     })
   },
   listMonths(state) {
     let months = Object.keys(state.monthly)
     return months.map(month => {
       return {
         value: month,
         text: mon[month]
       }
     })
   },
   yearSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.yearly)) {
         series.push(utils.numberFormat(state.yearly[key]["residential_avg_price"]))
     }
     return [{
       name: "",
       data: series
      }
     ]
   },
   quarterSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.quarterly)) {
         series.push(utils.numberFormat(state.quarterly[key]["residential_avg_price"]))
     }
     return [{
       name: "",
       data: series
      }
     ]
   },
   monthSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.monthly)) {
         series.push(utils.numberFormat(state.monthly[key]["residential_avg_price"]))
     }
     return [{
       name: "",
       data: series
      }
     ]
   },
   commercialYearSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.yearly)) {
         series.push(utils.numberFormat(state.yearly[key]["commercial_avg_price"]))
     }
     return [{
       name: "",
       data: series
      }
     ]
   },
   commercialQuarterSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.quarterly)) {
         series.push(utils.numberFormat(state.quarterly[key]["commercial_avg_price"]))
     }
     return [{
       name: "",
       data: series
      }
     ]
   },
   totalTransactionYearSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.yearly)) {
         series.push(utils.numberFormat(state.yearly[key]["total_transactions"]))
     }
     return [{
       name: "",
       data: series
      }
     ]
   },
   totalTransactionQuarterSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.quarterly)) {
         series.push(utils.numberFormat(state.quarterly[key]["total_transactions"]))
     }
     return [{
       name: "",
       data: series
      }
     ]
   },
   totalTransactionMonthSeries(state) {
    let series = []
    for (const [key] of Object.entries(state.monthly)) {
        series.push(utils.numberFormat(state.monthly[key]["total_transactions"]))
    }
    return [{
      name: "",
      data: series
     }
    ]
  },
   transactionsVolumeYearSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.yearly)) {
         series.push(state.yearly[key]["volume_of_transaction"])
     }
     return [{
       name: "",
       data: series
      }
     ]
   },
   transactionsVolumeQuarterSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.quarterly)) {
         series.push(state.quarterly[key]["volume_of_transaction"])
     }
     return [{
       name: "Statistics",
       data: series
      }
     ]
   },
   transactionsVolumeMonthSeries(state) {
    let series = []
    for (const [key] of Object.entries(state.monthly)) {
        series.push(state.monthly[key]["volume_of_transaction"])
    }
    return [{
      name: "Statistics",
      data: series
     }
    ]
  },
   latestTransactionQuarterSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.quarterly)) {
         series.push(state.quarterly[key]["latest_transaction"])
     }
     return [{
       name: "Statistics",
       data: series
      }
     ]
   },
   latestTransactionMonthSeries(state) {
    let series = []
    for (const [key] of Object.entries(state.monthly)) {
        series.push(state.monthly[key]["latest_transaction"])
    }
    return [{
      name: "Statistics",
      data: series
     }
    ]
  },
   latestTransactionYearSeries(state) {
     let series = []
     for (const [key] of Object.entries(state.yearly)) {
         series.push(state.yearly[key]["latest_transaction"])
     }
     return [{
       name: "Statistics",
       data: series
      }
     ]
   },
   reverseFavouritePlots(state) {
      let favourites = state.favourite_plots
      return favourites.reverse()
   },
   reverseFavouritePlans(state) {
    let favourites = state.favourite_plans
    return favourites.reverse()
 }
}
