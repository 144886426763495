
export default {
  SET_MONTHLY(state, monthly) {
    state.monthly = monthly
  },
  SET_MONTHLY_COMPARISON(state, monthly_comparison) {
    let categories = []

    for (const [key] of Object.entries(monthly_comparison)) {
      categories.push(key)
    }

    state.monthly_comparison = monthly_comparison
    state.monthly_comparison_categories = categories
  },
  SET_TOP_TRANSACTIONS(state, transactions) {
    state.top_transactions = transactions
  },
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions
  },
  SET_META(state, meta) {
    state.meta.total = meta.total
    state.meta.to = meta.to
    state.meta.from = meta.from
    state.meta.last_page = meta.last_page
    state.meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.meta.next_page = meta.current_page + 1
    }
   },
  SET_CLASSIFICATIONS(state, classifications) {
    state.classifications = classifications
  },
  SET_STAT_ONE(state, data) {
    state.statOne = data
  },
  SET_STAT_TWO(state, data) {
    state.statTwo = data
  },
}
