import axios from "../../axios/index.js"

export default {
  fetchPlans(meta) {
    return axios.get("customer/plans", {
      params: meta
    })
  },
  fetchPlans2(meta) {
    return axios.get("customer/plans-search", {
      params: meta
    })
  },
  favouritePlans() {
    return axios.get("customer/favourites/plans")
  },
  favouritePlots() {
    return axios.get("customer/favourites/plots")
  },
  fetchPlot(meta) {
    return axios.get("customer/plot-detail", {
      params: meta
    })
  },
  fetchPlanDetail(meta) {
    return axios.get("customer/plan-detail", {
      params: meta
    })
  },
  fetchPlan(meta) {
    return axios.get("customer/plans", {
      params: meta
    })
  },
  fetchYearlyStatistics(meta) {
    return axios.get("customer/statistics/yearly", {
      params: meta
    })
  },
  fetchQuarterlyStatistics(meta) {
    return axios.get("customer/statistics/quarterly", {
      params: meta
    })
  },
  fetchMonthlyStatistics(meta) {
    return axios.get("customer/statistics/monthly", {
      params: meta
    })
  },
  addFeedback(payload) {
    return axios.post("customer/feedback/new", payload)
  },
  addFavourite(meta) {
    return axios.post("customer/favourites/plots/new", meta)
  },
  createNewFavourite(meta) {
    return axios.post("customer/favourites/plots/create", meta)
  },
  addFavouritePlan(meta) {
    return axios.post("customer/favourites/plans/new", meta)
  },
  recordShareView(shareId) {
    return axios.post(`customer/share-plot/${shareId}`)
  },
  recordPlanView(shareId) {
    return axios.post(`customer/share-plan/${shareId}`)
  },
  removeFavouritePlot(plotId) {
    return axios.delete(`customer/favourites/plots/${plotId}`)
  },
  removeFavouritePlan(planId) {
    return axios.delete(`customer/favourites/plans/${planId}`)
  }
}
