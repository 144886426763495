export default {
  monthly: {},
  monthly_comparison: {},
  monthly_comparison_categories: [],
  top_transactions: [],
  classifications: [],
  transactions: [],
  meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  statOne: [],
  statTwo: [],
}
