const mutations = {
  CHANGE_ZOOM(state, value) {
    state.options.zoom = value
  },
  UPDATE_USER_INFO(state, payload) {
    state.AppActiveUser = payload
    localStorage.setItem("userInfo", JSON.stringify(payload))
  },
  SET_CITIES(state, cities) {
    state.cities = cities
  }

}

export default mutations
