import utils from '@/helper/utils.js'
import {month as mon} from '@/data/chartsData.js'

export default {
  SET_PLANS(state, plans) {
    state.plans = plans
  },
  SET_PLANS2(state, plans) {
    state.plans2 = plans
  },
  SET_FAVOURITE_PLANS(state, favourites) {
    state.favourite_plans = favourites
    //state.favourite_plans = favourites.reverse()
  },
  SET_FAVOURITE_PLOTS(state, favourites) {
    state.favourite_plots = favourites
    //state.favourite_plots = favourites.reverse()
  },
  REMOVE_FAVOURITE_PLOT(state, plotId) {
    const plotIndex = state.favourite_plots.findIndex(plot => plot.id === plotId)
    if(plotIndex != -1) {
      state.favourite_plots.splice(plotIndex, 1)
    }
  },
  REMOVE_FAVOURITE_PLAN(state, planId) {
    const planIndex = state.favourite_plans.findIndex(plan => plan.id === planId)
    if(planIndex != -1) {
      state.favourite_plans.splice(planIndex, 1)
    }
  },
  ADD_TO_PLANS(state, plans) {
     plans.forEach((data) => {
       let planIndex = state.plans.findIndex(plan => plan.id === data.id)
       if(planIndex === -1) {
         state.plans.push(data)
       }
     })
  },
  UPDATE_PLANS(state, plans) {
    let data = JSON.parse(JSON.stringify(state.plans)).concat(plans)
    state.plans = data
  },
  SET_PLOT(state, plot) {
    state.plot = plot
  },
  SET_PLAN_DETAIL(state, plan) {
    state.plan_detail = plan
  },
  SET_SHARED(state, shared) {
    state.shared = shared
  },
  SET_PLAN_SHARED(state, shared) {
    state.plan_shared = shared
  },
  SET_PLAN_COORDS(state, payload) {
    localStorage.setItem("plan", JSON.stringify(payload))
    state.plan = payload
  },
  SET_FAVOURITE(state, favourite) {
    state.favourite = favourite
  },
  SET_FAVOURITE_PLAN(state, favourite) {
    state.favourite_plan = favourite
  },
  SET_FEEDBACK(state, feedback) {
    state.feedback = feedback
  },
  SET_PLAN(state, plan) {
    localStorage.setItem('plan', JSON.stringify(plan))
    state.plan = plan
  },
  SET_YEARLY_STATS(state, yearly) {
    let categories = []
    state.yearly = yearly
    state.yearlySeries = []
    let series = []
    for (const [key] of Object.entries(yearly)) {
      categories.push(utils.hijriYear(key).toString())
      series.push(utils.numberFormat(yearly[key]["residential_avg_price"]))
    }
    state.yearlySeries = [
      {
        name: "Statistics",
        data: series
      }
    ]
    state.charts_categories = categories
  },
  SET_QUARTERLY_STATS(state, quarterly) {
    let categories = []
    state.quarterly = quarterly
    for (const [key] of Object.entries(quarterly)) {
      categories.push(key.toString())
    }
    state.quarterly_categories = categories
  },
  SET_MONTHLY_STATS(state, monthly) {
    let categories = []
    state.monthly = monthly
    for (const [key] of Object.entries(monthly)) {
      categories.push(mon[key])
    }
    state.monthly_categories = categories
  },
  SET_META(state, meta) {
   state.meta.total = meta.total
   state.meta.to = meta.to
   state.meta.from = meta.from
   state.meta.last_page = meta.last_page
   state.meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.meta.next_page = meta.current_page + 1
   }
  },
  UPDATE_VIEWS(state) {
    state.views += 1
  }
}
