import { extend, localize } from "vee-validate";
import { required, email, min,max } from "vee-validate/dist/rules";
import ar from "vee-validate/dist/locale/ar.json"

// Install required rule.
extend("required", required);

// Install email rule.
extend("email", email);

// Install min rule.
extend("min", min);
extend("max", max);

// Install Arabic localization

localize({
  ar: {
    messages: ar.messages,
    names: {
      email: "البريد الاليكتروني",
      password: "كلمة السر",
      first_name: "الاسم",
      last_name: "الاسم",
      phone: "رقم الجوال"
    },
    fields: {
      password: {
        min: "كلمة السر قصيرة جداً سيتم اختراقك"
      }
    }
  }
})

localize(ar)
