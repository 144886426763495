import axios from "../../axios/index.js"

export default {
  fetchMonthlyTransactionReport(meta) {
    return axios.get("customer/transaction-report/monthly", {
      params: meta
    })
  },
  fetchMonthlyComparison(meta) {
    return axios.get("customer/transaction-report/yearly", {
      params: meta
    })
  },
  topTransactions(meta) {
    return axios.get("customer/transaction-report/top-transactions", {
      params: meta
    })
  },
  fetchTransactions(meta) {
    return axios.get("customer/transactions", {
      params: meta
    })
  },
  fetchClassifications(meta) {
    return axios.get("customer/classifications", {
      params: meta
    })
  },
  fetchStatOne(meta) {
    return axios.get("customer/statistics/plotStat", {
      params: meta
    })
  },
  fetchStatTwo(meta) {
    return axios.get("customer/statistics/sub-classification", {
      params: meta
    })
  },
}
