import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'intersection-observer'

import Vue from 'vue'
import App from './App.vue'

//Get bootstrap components
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import "./vee-validate";

import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

import titleMixin from './mixins/titleMixin'

import Toast, { POSITION } from "vue-toastification"

import "vue-toastification/dist/index.css"

import VueTelInput from 'vue-tel-input'

import Vmodal from 'vue-js-modal'
import JsonExcel from "vue-json-excel";


const options = {
    timeout: 2000,
    position: POSITION.BOTTOM_RIGHT
}

Vue.use(Vmodal, {dialog: true})

Vue.use(VueTelInput)

Vue.use(Toast, options)

Vue.mixin(titleMixin)

Vue.use(Loading)


//import css
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/scss/custom.scss'

import './assets/scss/main.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import axios from './axios.js'

import router from './router'

import store from './store/store'

import i18n from './i18n/i18n'

Vue.prototype.$http = axios

const accessToken = localStorage.getItem('accessToken')

if (accessToken) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
}

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider);
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
