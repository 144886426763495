import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleCity from './city/moduleCity.js'
import modulePlan from './plan/modulePlan.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleTransaction from './transaction/moduleTransaction.js'

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
      city: moduleCity,
      plan: modulePlan,
      auth: moduleAuth,
      transaction: moduleTransaction
    },
    strict: process.env.NODE_ENV !== 'production'
})
